import React, { useState } from "react";
import topImage from "./topImage.gif";
import bottomImage from "./bottomImage.gif";
import sadgif1 from "./sadgif1.gif";
import sadgif2 from "./sadgif2.gif";
import jordi1 from "./jordi2.png";
import "./App.css";

const posiblesFrases = [
  "Tristemente aún no...",
  "Algún día...",
  "Yo también sigo soñando, hermano",
  "Ahora está en un lugar mejor...",
  "Tranquilos niños, él ahora está en un lugar feliz",
  "Él nos está vigilando desde allí arriba...",
  "Quizá algún día vuelva...",
  "Aún no hay noticias...",
  "Sigo acudiendo al muelle por si algún día aparece...",
  "Me ha parecido escuchar noticias suyas de un viajero",
  "Está lloviendo hoy... Está lloviendo mucho...",
  "Quizá el año que viene...",
  "Intentamos investigar, pero nos quedamos sin presupuesto...",
  "Seguimos sin noticias...",
  "Te echamos de menos, Jordi",
  "Los viajeros hablan de un dragón violeta en el norte...",
  "Nunca podremos superarlo, vuelve, Jordi",
  "Al menos espero que ahí estés feliz...",
  "Puede que allá haga frío, pero aquí nos has dejado helados...",
  "El mundo es peor sin Jordi...",
  "Intentaremos que se te recuerde en leyendas",
  "Brindemos esta noche por nuestro hermano caído",
  "Necesitaremos ayuda psicológica para superar esto",
  "Ahora sabemos lo que se siente al perder a alguien...",
  "Tendremos que superar la pérdida...",
  "Al menos estará colaborando a la ciencia...",
  "Ahora no tendremos a quién echarle la culpa de las cosas...",
  "Siempre nos quedará la esperanza de que vuelva por Navidad...",
  "Nadie volverá a ser el mismo sin ti, Jordi...",
  "Al menos el frío te conservará tal como te recordamos...",
  "En estos momentos el fin del mundo no suena tan mal...",
  "¡¡Te echamos de menos Jordi-senpai!!",
  "Sé que ahí nevará, pero no hace más frío que en nuestro corazón",
  "Con un poco de suerte podremos soñar con Jordi hoy...",
  "Podemos crear unas fotos para sentirlo más cerca...",
  "Barcelona sin Jordi se convierte en Barpañuelito...",
  "Podemos crear un simulador donde Jordi nunca se haya ido...",
  "Ya no hay buenos días... Solo días...",
  "Nunca sabes lo grande que puede ser la soledad hasta que tu Jordi falta...",
  "Aún no... Aún no...",
  "Yo también mantengo la esperanza...",
  "¡¡Jordi-kun tiene que volver!!",
  "Ais... Nuestro Jordi...",
  "Seguro que algún día...",
  "Aún escucho su risa en el viento...",
  "¿Qué estará pensando allá dónde esté?",
  "¿Se acordará él de nosotros?",
  "Dicen que si piensas mucho en él puede aparecer...",
  "Si dices su nombre tres veces mirando al espejo quizá aparezca detrás de ti...",
  "Nadie podrá sustituir a nuestro Jordi...",
  "No... puedo... soportarlo...",
];

const frasesHaVuelto = [
  "Sí!!!! HA VUELTOOOO!!!!",
  "EL HÉROE HA VUELTO A CASA!!!",
  "HA LLEGADOOOOO!!!!",
  "SÍÍ!! ES EL DÍA MÁS FELIZ DE MI VIDA!!!",
  "NUESTRO JORDIIII!!!!!",
  "(GRITO DE FANGIRL)",
  "HA VUELTO!!!!!!!!",
];

function App() {
  const [clicked, setClicked] = useState(false);
  const [message, setMessage] = useState("");
  const [modoEsperanza, setModoEsperanza] = useState(true);

  const onClick = () => {
    setClicked(true);
    setMessage("Comprobando...");
    setTimeout(() => {
      if (new Date().getTime() > 1668357595468) {
        const random = Math.floor(Math.random() * frasesHaVuelto.length);
        setMessage(frasesHaVuelto[random]);
      } else {
        const random = Math.floor(Math.random() * posiblesFrases.length);
        setMessage(posiblesFrases[random]);
      }
    }, 500);
  };
  return (
    <div className="App">
      <div className="wrapper">
        <input
          type="radio"
          name="select"
          id="option-1"
          checked={modoEsperanza}
          onClick={() => setModoEsperanza(true)}
        />
        <input
          type="radio"
          name="select"
          id="option-2"
          checked={!modoEsperanza}
          onClick={() => setModoEsperanza(false)}
        />
        <label for="option-1" className="option option-1">
          <div className="dot"></div>
          <span>Modo Esperanza</span>
        </label>
        <label for="option-2" className="option option-2">
          <div className="dot"></div>
          <span>Modo Realista</span>
        </label>
      </div>
      {modoEsperanza ? (
        <div className="innerContainer">
          <img src={topImage} className="topImage" alt="topImage" />
          <p className="topText">¿Ha vuelto Jordi a España ya?</p>
          <div id="btn" onClick={onClick}>
            <span className="noselect">
              {clicked ? "Comprobar de nuevo" : "Comprobar"}
            </span>
            <div id="circle"></div>
          </div>
          {clicked && <p>{message}</p>}
          <img src={bottomImage} className="bottomImage" alt="bottomImage" />
        </div>
      ) : (
        <div className="innerContainer">
          <img src={sadgif1} className="sadImage" alt="topImage" />
          <p className="topText">Siempre te recordaremos, amigo</p>
          <img src={jordi1} className="jordiImage" alt="JordiDEP" />
          <p className="bottomText">Que la nieve te sea leve...</p>
          <img src={sadgif2} className="sadImage" alt="bottomImage" />
        </div>
      )}
    </div>
  );
}

export default App;
